<template>
  <b-modal
    id="delete-contact"
    centered
    title="Delete Contact For This Client"
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="deleteContact"
  >
    <b-card-text> Are you sure you want to delete this contact for all clients? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props:{
    id:{
      type: [String, Boolean],
      default: false
    },
    invalid:{
      type: [Boolean],
      default: false
    }
  },
  emits: ["contact-submited"],
  methods:{
    deleteContact(){
      let params = this.id;
      if(this.invalid){
        params+="?invalid=true";
      }
      this.$http
        .get("/contacts/delete/"+ params)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('contact-submited')
        })
    }
  }
};
</script>
