<template>
  <b-card no-body class="action-buttons">
    <b-row class="mb-1 mx-1">

      <b-col md="6" xl="8">
        <button
          class="mr-50 mt-2 btn line shimmer-btns"
          v-for="iBtn in buttons"
          :key="iBtn"
        ></button>
      </b-col>

      <b-col md="6" xl="4" class="d-md-flex justify-content-end align-items-start" v-if="search">
        <button
          class="mt-2 btn line shimmer-search"
        ></button>
        <button
          class="mt-2 ml-1 btn line shimmer-search-btn"
        ></button>
      </b-col>

      <b-col md="6" xl="4" class="d-md-flex justify-content-end align-items-start" v-else-if="dateSearch">
        <button
          class="mt-2 btn line shimmer-search-date"
        ></button>
        <button
          class="mt-2 ml-1 btn line shimmer-search-date"
        ></button>
        <button
          class="mt-2 ml-1 btn line shimmer-search-btn"
        ></button>
      </b-col>

    </b-row>

    <table class="tg">
      <tr>
        <th class="tg-cly1" v-for="iCol in columns" :key="iCol">
          <div class="line"></div>
        </th>
      </tr>
      <tr v-for="iRow in rows" :key="iRow">
        <td class="tg-cly1" v-for="iCol in columns" :key="iCol">
          <div class="line"></div>
        </td>
      </tr>
    </table>
    <div class="d-flex align-items-center justify-content-center">
      <button
        class="mt-2 mb-2 text-center btn line shimmer-pagination"
      ></button>
    </div>
    
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";

export default {
  props: ["rows", "columns", "buttons", "search", "dateSearch"],

  components: {
    BCard,
    BCol,
    BRow,
    BButton,
  },
};
</script>

<style lang="scss" scoped>
// forked from https://codepen.io/viktorstrate/pen/yoBRLy

$base-color: #f4f4f4;
$shine-color: rgba(229, 229, 229, 0.8);
$animation-duration: 2s;
$avatar-offset: 52 + 16;

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #ccc;
  width: 100%;
}
.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 10px 5px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  background-color: #fff;
}
.tg th {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 5px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  background-color: #f0f0f0;
}

.line {
  float: left;
  width: 100px;
  height: 24px;
  margin-top: 12px;
  border-radius: 7px;

  @include background-gradient;
  animation: shine-lines $animation-duration infinite ease-out;
}

.shimmer-btns {
  height:40px !important;
  width:145px !important;
}
.shimmer-search{
    height:40px !important;
    width: 80% !important;
}
.shimmer-search-date{
    height:40px !important;
    width: 40% !important;
}
.shimmer-search-btn{
  height:40px !important;
  width:40px !important;
  border-radius: 50%;
}
.shimmer-pagination{
  height:40px !important;
  width:340px !important;
  border-radius: 25px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}
</style>