<template>
  <div class="component-height py-2 d-flex flex-column justify-content-center align-items-center">
    <img src="@/assets/images/icons/not-found.png" class="img-fluid"/>
    <h3 class="text-secondary mt-2" v-if="text">{{text}}</h3>
    <div class="mt-2" v-if="btnOneLink || btnTwoLink || btnOneToggle || btnTwoToggle">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="buttonOne"
        v-b-toggle="btnOneToggle"
        v-if="btnOneLink || btnOneToggle"
      >
        <slot name="btnOne"></slot>
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="ml-2"
        @click="buttonTwo"
        v-b-toggle="btnTwoToggle"
        v-if="btnTwoLink || btnTwoToggle"
      >
        <slot name="btnTwo"></slot>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";


export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BButton,
    BCol,
    BRow,
  },
  props:{
    text:{
      type: [String, Boolean],
      default: false
    },
    btnOneLink:{
      type: [String, Boolean],
      default: false
    },
    btnOneToggle:{
      type: [String, Boolean],
      default: false
    },
    btnTwoLink:{
      type: [Boolean],
      default: false
    },
    btnTwoToggle:{
      type: [String, Boolean],
      default: false
    },
  },
  methods:{
    buttonOne(){
      if(!this.btnOneToggle){
        this.$router
          .push({ 
            name: this.btnOneLink,
          })
      }
      
    },
    buttonTwo(){
      if(!this.btnTwoToggle){
        this.$router
          .push({ 
            name: this.btnTwoLink,
          })
      }
    },
  }
}
</script>

<style scoped>
.component-height{
  min-height:65vh;
}
</style>