<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :search="true" />

    <no-records-found
      v-if="showNotFound"
      text="No contacts are added yet by this client"
    />

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col md="6" xl="8">
          <div class="inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                !selectedContacts.length
                  ? 'outline-secondary'
                  : 'outline-primary'
              "
              class="mr-50 mt-2"
              v-b-modal.delete-bulk-contact
              :disabled="!selectedContacts.length"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span class="align-middle">Delete</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="6" xl="4" class="mt-2">
          <!-- basic -->
          <b-form-group>
            <b-input-group>
              <b-form-input
                v-model.trim="search"
                placeholder="Search for contacts"
              />

              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon rounded-circle ml-1"
                  @click="contactRefresh"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="contacts"
        :fields="fields"
        ref="contactsTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #head(ID)>
          <b-form-checkbox
            v-model="allSelected"
            @change="selectAll"
            :value="true"
            :unchecked-value="false"
          >
          
          </b-form-checkbox>
        </template>

        <template #cell(ID)="data">
          <b-form-checkbox
            v-model="selectedContacts"
            :value="data.value"
          >
          </b-form-checkbox>
        </template>

        <template #cell(name)="row">
          <span> {{ row.item.first_name }} {{ row.item.last_name }}</span>
        </template>

        <template #cell(phone)="row">
          <span> {{ row.item.code }}{{ row.item.phone }}</span>
        </template>
        
        <template #cell(group_name)="row">
          <span v-for="group, index in row.item.ContactGroup" :key="group.id">
            {{group.name}}<span v-if="index != row.item.ContactGroup.length - 1">,</span>
          </span>
        </template>

        <template #cell(client)="row">
          <span v-for="group, index in row.item.ContactGroup" :key="group.id">
            {{ group.user.first_name }} {{ group.user.last_name }}<span v-if="index != row.item.ContactGroup.length - 1">,</span>
          </span>
        </template>

        <template #cell(actions)="row">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="btn-icon rounded-circle ml-lg-1 mt-1 mt-lg-0"
            v-b-modal.delete-contact
            @click="deleteContact(row.item.ID)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button> 
          
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>

    </b-card>
    <delete-single-contact
      :id="contactId"
      @contact-submited="contactRefresh"
    />
    <delete-bulk-contact
      :ids="selectedContacts"
      @contact-submited="contactRefresh"
    />
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
} from "bootstrap-vue";
import DeleteSingleContact from "./components/DeleteSingleContact.vue";
import DeleteBulkContact from "./components/DeleteBulkContact.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    DeleteSingleContact,
    DeleteBulkContact,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    return {
      fields: [
        {
          key: "ID",
          label: "SELECTION",
        },
        {
          key: "name",
          label: "NAME",
        },
        {
          key: "phone",
          label: "PHONE NUMBER",
        },
        {
          key: "format_created_at",
          label: "DATE ADDED",
        },
        {
          key: "group_name",
          label: "GROUPS",
        },
        {
          key: "client",
          label: "CLIENT",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      selectedContacts: [],
      allContactsId: [],
      allSelected: false,
      groups: [],
      search: "",
      contactId: false,
      downloadIsLoading: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    contacts(ctx, callback) {
      let params = "?page=" + ctx.currentPage;
      if (this.search) {
        params += "&search=" + this.search;
      }
      this.$http
        .get("/contacts/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.allSelected = false;
          this.allContactsId = [];
          this.selectedContacts = [];
          for (let contact of items) {
            this.allContactsId.push(contact.ID);
          }
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedContacts = this.allContactsId;
      } else {
        this.selectedContacts = [];
      }
    },
    contactRefresh() {
      this.contactId = false;
      this.$refs.contactsTable.refresh();
    },
    deleteContact(id) {
      this.contactId = id;
    },
    ResetContactId() {
      this.contactId = false;
    },
  },
};
</script>