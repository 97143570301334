<template>
  <b-modal
    id="delete-bulk-contact"
    centered
    title="Delete Contacts For This Client"
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="DeleteBulkContact"
  >
    <b-card-text> Are you sure you want to delete all the selected contacts for all clients? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props:{
    ids:{
      type: Array,
      default: []
    },
    invalid:{
      type: [Boolean],
      default: false
    }
  },
  emits: ["contact-submited"],
  methods:{
    DeleteBulkContact(){
      let params;
      if(this.invalid){
        params+="?invalid=true";
      }
      this.$http
        .post("/contacts/delete" + params,{
          ids:this.ids,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('contact-submited');
        })
        .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        })
    }
  }
};
</script>
